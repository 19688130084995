<script>
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import rooms from "./rooms";
import {getDropDownElementsByTypeApi} from "@/api/common";
// import SideButtons from '../../../../../../components/side-buttons.vue';
const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

export default {
    page: {
        title: "Reception ODS",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {/*SideButtons*/rooms},
    props:{
      ODS:{}
    },
    data() {
      return {
      tableData: [],
      title: "Ordre de Service",
      selectedBdv:0,
      restaurants:[],
      nows:now,
      ODS_info: {
        Bdv:'',
        ID_CTT:'',
        contract:'',
        prestataire:'',
        date_ODS:'',
        date_exec_ODS:'',
        period:'',
        comments:''
      },
      Repas:[],
      send:false,
      RepasService:[],
      typeRepas:[],
      have:false,
      keys:'',
      bases:[],
      prests:[],
      contracts:[],
      periods:[],
      filtredRepas:[]

    };
  },
  watch:{
    ODS:{
      handler(val){
        if(val.length > 0 && val[0].id){
          this.ODS_info.id            = val[0].id;
          this.ODS_info.type_ODS      = val[0].type_ODS;
          this.ODS_info.Bdv           = val[0].Bdv;
          this.ODS_info.ID_CTT        = val[0].contract.id;
          this.ODS_info.contract      = val[0].contract;
          this.ODS_info.prestataire   = val[0].service_provider;
          this.ODS_info.date_ODS      = val[0].date_ODS;
          this.ODS_info.date_exec_ODS = val[0].date_exec_ODS;
          // this.ODS_info.period        = val[0].period;
          this.ODS_info.lifebase      = val[0].lifebase;
          this.ODS_info.done          = val[0].done;
          this.ODS_info.items         = val[0].items;
          this.ODS_info.read_at       = val[0].read_at;
          // this.RepasService           = Object.keys(val[0].items);
          this.filtredRepas           = val[0].items;
          this.filtredRepas.forEach((element,i) => {
            this.Repas[i]=undefined;
          });
          this.selectedContract       = val[0].contract;
          this.selectedBdv            = val[0].lifebase;
          this.bases.push(this.selectedBdv);
          this.selectedPrest          = val[0].service_provider;
          this.have                   = true;
        }
        else this.resetData()
      }
    }
  },
  mounted(){
    this.getLifeBases();
    // this.getServiceRepas();
    // this.getlistPeriod();
  },

  methods:{
    getLifeBases(){
      this.$http.post('/base/lifebases/list')
      .then(response => {
          this.bases = response.data.original.list;
      })
      .catch(error => console.log(error))
    },
    onComplete(){

      Swal.fire({
            title: 'Êtes vous sûr ?',
            text: 'de vouloir valider la réalisation de cet ODS ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          })
          .then((result) => {
            if (result.isConfirmed) {
              console.log(this.Repas);
              this.$http.post('/ods/OdsRestauration/reception',{
                  ods:this.ODS_info, items:this.Repas
              })
              .then(response => {
                  this.send = true;
                  this.$emit('odsvalues',response.data);
                  Swal.fire("Félicitations!", "Commande Receptionné avec succées", "success");
                  this.resetData();
                  this.send=false;
              
              })
              .catch(error => console.log(error))
            }
          })
      
      
    },
    TodayDate(){
        var td, dt = new Date;
        td = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

        return td;
    },
    onValidate(data){
      Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de vouloir accusé la réception de cet ODS ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              this.$http.post('/ods/OdsRestauration/validate',{
              ods:this.ODS_info,status:data
              })
              .then(response => {
                  this.send = true;
                  this.$emit('odsvalues',response.data);
                  if(data == "Recieved") Swal.fire("Félicitations!","L'accusé de réception de l' " +this.ODS_info.ref+" a été bien envoyé", "success");
                  
                  this.resetData();
                  this.send=false;
                  this.validate=false;
                  this.confirm=false;
              })
              .catch(error => console.log(error))
            }
          })
          
    },
    resetData(){
      this.ODS_info = {
        Bdv:'',
        ID_CTT:'',
        prestataire:'',
        contract:'',
        date_ODS:'',
        date_exec_ODS:'',
        period:'',
        comments:''
      },
      // this.RepasService = [],
        this.filtredRepas = [],
        this.have=false
      this.send = true;
    }
  }
    
}
</script>

<template>
    <div class="row">
      <div class="col-12">        
              <div class="p-3">
                <div class="">   
                  <div class="row" v-if="ODS_info.id&&ODS_info.date_exec_ODS != nows ">
                    <div class="col">
                      <b-alert variant="warning" show >
                          <i class="mdi mdi-alert-circle"></i> L'enregistrement et la confirmation sera active uniquement le jour de l'execution. Le {{ODS_info.date_exec_ODS_format}}
                      </b-alert>
                    </div>
                  </div>              
                      <div class="row">
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label for="formrow-password-input">N° Contrat</label>
                                <input class="form-control" readonly v-model="ODS_info.contract.ref" />
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label for="formrow-inputCity"
                                  >Nom de la Base *</label
                                >
                                <input v-if="ODS_info.lifebase" class="form-control" readonly v-model="ODS_info.lifebase.name"/>
                                <input v-else class="form-control" readonly v-model="ODS_info.Bdv"/>
                              </div>
                            </div>

                            <div class="col-lg-4">
                                <label for="">Prestataire *</label>
                                <input v-if="ODS_info.prestataire" class="form-control" readonly v-model="ODS_info.prestataire.prestname"/>
                                <input v-else class="form-control" readonly v-model="ODS_info.prestataire"/>
                                
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-4">
                                <label for="">Date ODS</label>
                                <input type="date" readonly class="form-control" v-model="ODS_info.date_ODS">
                            </div>
                            <div class="col-lg-4">
                                <label for="">Date execution</label>
                                <input type="date" readonly class="form-control" v-model="ODS_info.date_exec_ODS">
                            </div>
                            <!-- <div class="col-lg-4">
                                <label for="">Type de Periode *</label>
                                <select class="form-control" disabled v-model="ODS_info.period">
                                    <option value="0">Selectionner la periode</option>
                                    <option v-for="period in periods" :key="period.id" :value="period.id">{{period.designation}}</option>
                                </select>
                            </div> -->
                        </div>

                      <!-- end row -->
                </div>
                <!-- end card-body -->
          </div>
          <div class="p-3">
            <h4>Prestations</h4>
            <div class="row mt-2" v-for="(roomType,i) in filtredRepas" :key="i">
              <div class="col" >
                <rooms 
                    :ODS="ODS_info"
                    :pos="i"
                    :service="roomType"
                    @getvalues="Repas[`${i}`] = $event"
                />
              </div>
            </div>
          </div>
          
          
          <div class="row">
            <div class="col text-right mr-2 mb-3" v-if="ODS_info.id">
              <b-button :disabled="ODS_info.done == 1 || ODS_info.read_at != null" @click="onValidate('Recieved')" variant="info" class="btn-label mr-2">
                  <i class="fas fa-paper-plane label-icon"></i> Accusé de Réception
              </b-button>

              <b-button :disabled="ODS_info.done == 1 || ODS_info.date_exec_ODS != nows || (ODS_info.date_exec_ODS == nows && ODS_info.read_at == null)" @click="onComplete" variant="primary" class="btn-label">
                  <i class="bx bx-check-double label-icon"></i> Enregitrer & Confirmer
              </b-button>
              
            </div>
          </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
